import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// import { ThemeProvider } from '@material-ui/styles';
import Input from '@material-ui/core/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
// import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


// const useStyles = withStyles({
//     //   parent:{
//     //       display:'grid'
//     //   },
//     paper: {
//         padding: 1,
//         textAlign: 'left',
//         color: 'white',
//         whiteSpace: 'nowrap',
//         marginBottom: 1,
//     }
// });


export default function Competitions(props) {
    return (
        <div className='competitions'>
            {/* <AppBar style={{ backgroundColor: 'darkgrey' }} > */}
            <div style={{ fontSize: 30, fontWeight: "bold", color: "white", borderBottom: "solid" }}>Competitions</div>
            {/* </AppBar> */}
            {/* <CompetitionLink group='Group' description='Competition' winner='Winner' runnerup="Runner-Up" startDate="Start Date" endDate="End Date" showTitle='true' /> */}
            <Grid container spacing={2} className='gridcont' style={{ maxHeight: '100%', overflow: 'auto' }} >
                {props.competitions.sort((a, b) => {
                    if (b.startDate > a.startDate) { return 1; }
                    if (b.startDate < a.startDate) { return -1; }
                    return 0;
                }).map(card => <CompetitionRow key={card.id} {...card} setCurrentCompetition={props.setCurrentCompetition} saveNewCompetition={props.saveNewCompetition} />)}
                {props.mgn &&
                    <Fab color="primary" aria-label="add" id="AddButton" onClick={() => props.addEmptyItem()} >
                        <AddIcon />
                    </Fab>
                }
            </Grid>
        </div>
    );
};

function CompetitionRow(props) {
    let startDate = new Date(props.startDate);
    let endDate = new Date(props.endDate);
    let showLink = startDate <= new Date();
    let highlight = (endDate >= new Date()) && showLink;
    return (
        <div>
            {props.id !== '0' && <CompetitionLink key={props.id} {...props} setCurrentCompetition={props.setCurrentCompetition} showLink={showLink} link='/standings/' highlight={highlight} />}
            {props.id === '0' && <CompetitionInput key={props.id} {...props} saveNewCompetition={props.saveNewCompetition} link='/' />}
        </div>
    );
};

const CompetitionLink = (props) => {
    // const classes = useStyles();
    return (
        <Paper className='paper' id={props.highlight ? 'highlight' : 'paper'}>
            <Link to={props.link} style={{ textDecoration: 'none' }} onClick={() => props.setCurrentCompetition(props)}>
                <Grid item>
                    {/* <div className='competitionsTitle'></div> */}

                    {/* {!props.showTitle && props.showLink && <div className='competitionsRow'><Link to={"/standings/"} onClick={() => props.setCurrentCompetition(props)}>{props.description}</Link></div>} */}
                    <div className='competitionsRow'>{props.description}</div>
                    <div className='competitionsRow'>Group: {props.group}</div>
                    <div className='competitionsRow'>Winner: {props.winner}</div>
                    <div className='competitionsRow'>Runner-up: {props.runnerup}</div>
                    <div className='competitionsRow'>{props.startDate} thru {props.endDate}</div>
                    {/* <div className='competitionsRow'></div> */}
                </Grid>
            </Link>
        </Paper>
    );
};

class CompetitionInput extends React.Component {
    state = {
        newRow: {}
    }
    componentDidMount = () => {
        this.setState(
            { newRow: { ...this.props } }
        )
    }
    render() {
        return (
            <Paper className='paper' id='paper'>
                <Grid item>
                    {/* <div className='competitionsTitle'></div> */}
                    <div className='competitionsRow'>
                        <Input defaultValue={this.props.group} placeholder="Group" />
                    </div>
                    <div className='competitionsRow'>
                        {this.props.description}
                    </div>
                    <div className='competitionsRow'>{this.props.winner}</div>
                    <div className='competitionsRow'>{this.props.runnerup}</div>
                    <div className='competitionsRow'>
                        <Input defaultValue={this.props.startDate} placeholder="YYYY-MM-DD"
                            onBlur={(event) => {
                                let myVal = event.target.value;
                                this.setState(prevState => ({
                                    newRow: {
                                        ...prevState.newRow,
                                        startDate: myVal
                                    }
                                }))
                            }
                            } />
                    </div>
                    <div className='competitionsRow'>
                        <Input defaultValue={this.props.endDate} placeholder="YYYY-MM-DD"
                            onBlur={(event) => {
                                let myVal = event.target.value;
                                this.setState(prevState => ({
                                    newRow: {
                                        ...prevState.newRow,
                                        endDate: myVal
                                    }
                                }))
                            }
                            } />

                    </div>
                    <div>
                        <FontAwesomeIcon icon={faCheck} className='check' onClick={() => this.props.saveNewCompetition(this.state.newRow)} />
                    </div>
                </Grid>
            </Paper>
        )
    };
};