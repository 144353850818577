import React, { useState } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from './ImagePreview.js'; // source code : ./src/demo/AppWithImagePreview/ImagePreview
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tesseract from 'tesseract.js';



export default function CameraDialog(props) {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const setWeight = (val) => {
        props.setWeightValue(val);
    };
    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open form dialog
        </Button> */}
            <AddAPhotoIcon onClick={handleClickOpen} />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Weight Capture (Beta- doesn't save)</DialogTitle>
                <DialogContent>
                    <CameraDiv setWeightValueDiag={setWeight} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Done
            </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

function CameraDiv(props) {
    const [dataUri, setDataUri] = React.useState('');


    const setWeight = (val) => {
        props.setWeightValueDiag(val);
    };

    const setImageSrc = (imageFile) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = function () {
                if (typeof fr.result !== 'string') {
                    return reject(null);
                }

                return fr.result;
            };
            fr.onerror = reject;
            fr.readAsDataURL(imageFile);
        });
    };

    function handleTakePhotoAnimationDone(val) {
        console.log(val);
        console.log('takePhoto');
        setDataUri(val);
        Tesseract.recognize(
            val,
            'eng',
            { logger: m => console.log(m) }
        ).then(({ data: { text } }) => {
            setWeight(text);
            console.log(text);
        })
    }

    const isFullscreen = false;
    return (
        <div>
            {
                (dataUri)
                    ? <ImagePreview dataUri={dataUri}
                        isFullscreen={isFullscreen}
                    />
                    : <Camera
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        imageType={IMAGE_TYPES.JPG}
                        imageCompression={0.97}
                        idealResolution={{ width: 640, height: 480 }}
                        isMaxResolution={true}
                        isImageMirror={false}
                        isSilentMode={false} onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                        isFullscreen={isFullscreen}
                    />
            }
        </div>
    );

}

