import React from 'react';
import CameraDialog from '../Camera'

class Form extends React.Component {
    state = {
        weight: '',
        dataUri: ''
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.weight);
    };

    setWeightValueTop = (val) => {
        // this.setState({ weight: val });
        // can't have this with onChange event - also translation is weak
    }


    render() {
        return (
            <div style={{ width: '100%' }}>
                <form onSubmit={this.handleSubmit}>
                    <input type="text"
                        value={this.state.weight}
                        onChange={(event) => this.setState({ weight: event.target.value })}
                        ref={(input) => this.weightInput = input}
                        placeholder={"Enter Week " + this.props.week} required />
                    <button type="submit">Add Weight</button>
                </form>
                <CameraDialog setWeightValue={this.setWeightValueTop} />
            </div>
        );
    }
}

export default Form;