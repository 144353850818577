import React from 'react';


const Standings = (props) => {
  return (
    <div className='standings'>
      <div style={{ fontSize: 30, fontWeight: "bold", color: "white", borderBottom: "solid" }}>{props.title}</div>
      {props.standings.map(row => <StandingsRow key={row.rank} {...row} />)}
    </div>
  );
};

class StandingsRow extends React.Component {
  render() {
    return (
      <div>
        {/* <div className='standingsGrid'> */}
        <div className={this.props.uptodate ? 'standingsGrid badplayer' : 'standingsGrid'} >
          <div className='standingsTitle'></div>
          <div className='standingsRow'>{this.props.rank}</div>
          <div className='standingsRow'>{this.props.player}</div>
          <div className='standingsRow'>{this.props.loss}</div>
          <div className='standingsRow'>{this.props.losslbs}</div>
          <div className='standingsRow fadeshow'>{this.props.losstotal}</div>
          {/* 
          <div className='standingsRow fadeshow'>{this.props.weekrank}</div> */}
          <div className='standingsRow fadeshow'>{this.props.lastweek}</div>
        </div>
      </div>
    )
  };
};





export default Standings;