import React from 'react'
import {render} from 'react-dom'
import Downshift from 'downshift'


  class Autocompletedrop extends React.Component {
    state={
      items : [
        {value: 'apple'},
        {value: 'pear'},
        {value: 'orange'},
        {value: 'grape'},
        {value: 'banana'},
      ],
      selectedValue:''
    }
  render() {
    return(
      <div>
    <Downshift
      onChange={selection => this.props.setweight(selection.value)}
      itemToString={item => (item ? item.value : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => (
        <div>
          <label {...getLabelProps()}></label>
          <input {...getInputProps()} placeholder="Player To Assume"/>
          {isOpen ? (
            <div>
              {this.props.items
                .filter(item => !inputValue || item.value.includes(inputValue))
                .map((item, index) => (
                  <div
                    {...getItemProps({
                      key: item.value,
                      index,
                      item,
                      style: {
                        backgroundColor:
                          highlightedIndex === index ? 'lightgray' : 'white',
                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                      },
                    })}
                  >
                    {item.value}
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      )}
    </Downshift><button type="submit">Assume Role</button></div>)};
  }
    export default Autocompletedrop;