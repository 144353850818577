import React, { Component } from 'react';
import './App.css';
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';
//import * as firebase from 'firebase';
import { auth } from 'firebaseui';
import Autocompletedrop from './Autocompletedrop';
import firebaseConfig from './firebaseconfig';
import axios from "axios";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Competitions from './Competitions_new';
import StandingsDiv from './views/OverallView';
import OneWeekDiv from './views/OneWeekView';
import BiWeekDiv from './views/BiWeekView';
import RawdataDiv from './views/RawdataView';
import AnalyticsDiv from './views/AnalyticsView'
import Menu from './views/Menu';

firebase.initializeApp(firebaseConfig);

const uiConfig = {
  signInSuccessUrl: 'success',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '<your-tos-url>',
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign('<your-privacy-policy-url>');
  }
};
// Initialize the FirebaseUI Widget using Firebase.
const ui = new auth.AuthUI(firebase.auth());
// The start method will wait until the DOM is loaded.
ui.start('#firebaseui-auth-container', uiConfig);

class AssumeRole extends React.Component {
  state = { weight: '' };
  setweight = (weightvalue) => {
    this.setState({ weight: weightvalue });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.weight);
    this.setState({ weight: '' });
  };
  render() {
    return (
      <div style={{ width: '100%' }}>
        <form onSubmit={this.handleSubmit}>
          <Autocompletedrop items={this.props.items} setweight={this.setweight} />
        </form>
      </div>
    );
  }
}

const AuthDiv = (props) => {
  return (
    <div>
      <div id="firebaseui-auth-container">
      </div>
    </div>
  );
}
class App extends Component {
  state = {
    standings: [
      {
        rank: 'Rank', player: 'Player', losstotal: 'Total Loss', lossweek: 'Week Loss', lossbiweek: 'Bi-Week Loss', weekrank: 'Weekly Rank'
        , lastweek: "Thru Week", startingweight: "start", currentweight: "now"
      },
    ],
    rawdata: [
      { week: 'Week', weight: 'Weight' },
    ],
    appuser: null,
    week: 0,
    competition: "",
    compstartdate: new Date(),
    compenddate: new Date(),
    weekentered: false,
    admin: false,
    maxweek: 0,
    players: [],
    competitions: [],
    iconStyle: { textDecoration: 'none', color: 'darkslategrey', fontSize: '39px', margin: '9px' }
  };

  addNewWeight = (cardInfo) => {
    // cardInfo.forEach(element => {
    //  const maxidrow = this.state.rawdata.reduce((prev, current) => (prev.id > current.id) ? prev : current, 1);
    // const maxweekrow = this.state.rawdata.reduce((prev, current) => (prev.id > current.id) ? prev : current, 1); 
    var token = localStorage.getItem("notification-token");
    axios.get('https://us-central1-calorieninja.cloudfunctions.net/addToTopic?text=' + token + '&comp=' + this.state.competition);

    this.setState(prevState => ({
      rawdata: prevState.rawdata.concat({ week: this.state.week, weight: cardInfo })
    }));
    var firestore = firebase.firestore();
    var docRef = firestore.doc("competitions/" + this.state.competition + "/Players/" + this.state.appuser + "/Weeks/" + this.state.week);
    docRef.set(
      { week: this.state.week, weight: cardInfo }
    )
    var docRefPlayer = firestore.doc("competitions/" + this.state.competition + "/Players/" + this.state.appuser);
    docRefPlayer.get().then((doc1) => {
      var doc = doc1.data();
      if (doc && doc.startingweight) {
        var sw = doc.startingweight;
        docRefPlayer.set(
          {
            twoweekweight: doc.lastweekweight, lastweekweight: doc.currentweight, currentweight: cardInfo, startingweight: sw, player: this.state.appuser.split(" ")[0],
            losstotal: (((sw - cardInfo) / sw) * 100).toFixed(2) + "%", tablesort: (((sw - cardInfo) / sw) * 100).toFixed(2), lossweek: (((doc.currentweight - cardInfo) / doc.currentweight) * 100).toFixed(2) + "%"
            , lossbiweek: (((doc.lastweekweight - cardInfo) / doc.lastweekweight) * 100).toFixed(2) + "%"
            , lastweek: this.state.week
          }
        );
      } else {
        var sw = cardInfo;
        docRefPlayer.set(
          {
            twoweekweight: cardInfo, lastweekweight: cardInfo, currentweight: cardInfo, startingweight: sw, player: this.state.appuser.split(" ")[0],
            losstotal: "0%", tablesort: 1, lossweek: "0%", lastweek: 0
          }
        );
      }
    });
    if (this.state.maxweek > this.state.week) {
      this.setState(prevState => ({ week: prevState.week + 1 }))
    } else { this.setState({ weekentered: true }); }
  };

  sendNotification = (user, week) => {
    axios.post('https://us-central1-calorieninja.cloudfunctions.net/pushToTopic', {
      "notification": {
        "title": "CalorieNinja",
        "body": user + " added weight for week " + week,
        "click_action": "https://www.calorieninja.com/"
      },
      "to": "/topics/calorie"
    });

  }

  askForPermissionToRecieveNotifications = async () => {
    try {
      const messaging = await firebase.messaging();

      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log("token:", token);
      localStorage.setItem("notification-token", token);
      this.setState({ messageToken: token })
      // const instance = axios.create({
      //   baseURL: 'https://iid.googleapis.com/iid/v1/',
      //   timeout: 1000,
      //   headers: {'Authorization': 'key=AAAA9kZWsfU:APA91bGSLIH9ptGDgX-iA9FRiw24wdxTY1P_0mI12yttQWT6dsWWCGW7-uj0BHb6wNj0T1Qvs3YUXgwSfSbZr2JyzJpj9E1QOuD4pAizySIzS7sjhzcpXn2SAM0OsLbMk-8DZN-sqT1K'}
      // });

      axios.get('https://us-central1-calorieninja.cloudfunctions.net/addToTopic?text=' + token + '&comp=calorie');

      await messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        alert(payload.notification.body);
        // ...
      });
      return token;

    } catch (error) {
      console.error(error);
    }
  }

  assumeUser = (user) => {
    this.setState(prevState => ({
      weekentered: false,
      standings: [
        {
          rank: 'Rank', player: 'Player', losstotal: 'Total Loss', losslbs: 'Lb Loss', lossweek: 'Week Loss', lossbiweek: 'Bi-Week Loss', weekrank: 'Weekly Rank'
          , lastweek: "Thru Week"
        },
      ],
      rawdata: [
        { week: 'Week', weight: 'Weight' },
      ],
      week: 0
    }));
    this.setUser(user);
  };

  setCurrentCompetition = (comp) => {
    this.setState({
      competition: comp.id,
      compstartdate: new Date(comp.startDate),
      compenddate: new Date(comp.endDate)
    }, () => {
      var firestore = firebase.firestore();
      var docRef = firestore.collection("competitions/" + this.state.competition + "/Players/" + this.state.appuser + "/Weeks");
      docRef.get().then((querySnapshot) => {
        this.setState({
          rawdata: [{ week: 'Week', weight: 'Weight' }]
        });
        if (querySnapshot.empty) {
          this.setState({ week: 0 });
        }
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          this.setState(prevState => ({
            rawdata: prevState.rawdata.concat(doc.data())
          }));

          if (doc.data().week === this.state.maxweek) {
            this.setState({ weekentered: true })
          } else {
            if (doc.data().week >= this.state.week) {
              this.setState({ week: doc.data().week + 1 });
            }
          };
        });
      }); //docref


      //var firestore = firebase.firestore();
      var docRefPlayers = firestore.collection("competitions/" + this.state.competition + "/Players");
      docRefPlayers.onSnapshot((querySnapshot) => {
        this.setState({
          standings: [
            {
              rank: 'Rank', player: 'Player', losstotal: 'Total Loss', losslbs: 'Lb Loss', lossweek: 'Week Loss', lossbiweek: 'Bi-Week Loss', weekrank: 'Weekly Rank'
              , lastweek: 'Thru Week'
            },
          ],
          players: []
        });
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          this.setState(prevState => ({
            standings: prevState.standings.concat(doc.data()),
            players: prevState.players.concat({ value: doc.id })
          }));
          if (doc.data().week === this.state.week) {
            this.setState({ weekentered: true })
          };
        });
      });
      var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
      var rightnow = new Date();
      var calcweek = Math.floor(((rightnow > this.state.compenddate ? this.state.compenddate : rightnow) - this.state.compstartdate) / ONE_WEEK);
      this.setState({ maxweek: calcweek });

    } //setstate
    );
  };

  addEmptyItem = () => {
    this.setState(prevState => ({
      competitions: prevState.competitions.concat({ id: '0', startDate: '', endDate: '', group: 'Tyson', description: '', winner: '', runnerup: '' })
    }));
  };

  saveNewCompetition = (props) => {
    // comics: this.state.comics.map(el => (el.id === id ? Object.assign({}, el, { gotIt }) : el))
    const startDate = new Date(props.startDate);
    const month = startDate.toLocaleString('default', { month: 'short' });
    const year = startDate.getFullYear()
    const comp = {
      id: props.group.toLowerCase() + month.toLowerCase() + startDate.getFullYear(),
      description: startDate.toLocaleString('default', { month: 'long' }) + ' ' + year,
      startDate: props.startDate,
      endDate: props.endDate,
      group: props.group,
      winner: props.winner,
      runnerup: props.runnerup
    }

    this.setState(prevState => ({
      competitions: prevState.competitions.filter(x => x.id !== '0').concat(props)
    }));

    var firestore = firebase.firestore();
    var docRef = firestore.doc("competitions/" + comp.id);
    docRef.set(
      comp
    )
  };

  setUser = (user) => {

    this.setState({ appuser: user }, () => {
      if (this.state.appuser === "Ian Baker") {
        this.setState({ admin: true });
      }

      var firestore = firebase.firestore();
      var docRef = firestore.collection("competitions/");
      docRef.onSnapshot((querySnapshot) => {
        this.setState({
          competitions: []
        });

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          this.setState(prevState => ({
            competitions: prevState.competitions.concat(doc.data())

          }));

        });
      });




    }
    );


    if (!localStorage.getItem("notification-token")) {
      this.askForPermissionToRecieveNotifications();
    }
  };
  render() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && !this.state.appuser) {
        // User is signed in.
        this.setUser(user.displayName);
      } else {
        // User is signed out.
        // ...
      }
    });

    return (
      <div className="App" >

        {!this.state.appuser && <AuthDiv setUser={this.setUser} />}
        {this.state.appuser &&
          <Router>

            <Switch>
              <Route path="/" exact
                render={(props) => <CompetitionsDiv competitions={this.state.competitions}
                  setCurrentCompetition={this.setCurrentCompetition}
                  addEmptyItem={this.addEmptyItem} saveNewCompetition={this.saveNewCompetition} mgn={this.state.admin} />}
              />
              {this.state.competition !== '' && <Route path="/standings/"
                render={(props) => <StandingsDiv appuser={this.state.appuser} weekentered={this.state.weekentered} standings={this.state.standings}
                  addNewWeight={this.addNewWeight} week={this.state.week} maxweek={this.state.maxweek} mgn={this.state.admin} iconStyle={this.state.iconStyle} />}
              />}
              {this.state.competition !== '' && <Route path="/oneweek/"
                render={(props) => <OneWeekDiv appuser={this.state.appuser} weekentered={this.state.weekentered} standings={this.state.standings}
                  addNewWeight={this.addNewWeight} week={this.state.week} maxweek={this.state.maxweek} mgn={this.state.admin} iconStyle={this.state.iconStyle} />}
              />}
              {this.state.competition !== '' && <Route path="/biweek/"
                render={(props) => <BiWeekDiv appuser={this.state.appuser} weekentered={this.state.weekentered} standings={this.state.standings}
                  addNewWeight={this.addNewWeight} week={this.state.week} maxweek={this.state.maxweek} mgn={this.state.admin} iconStyle={this.state.iconStyle} />}
              />}
              {this.state.competition !== '' && <Route path="/rawdata/"
                render={(props) => <RawdataDiv rawdata={this.state.rawdata} title={this.state.appuser} mgn={this.state.admin} iconStyle={this.state.iconStyle} />}
              />}
              {this.state.competition !== '' && <Route path="/analytics/"
                render={(props) => <AnalyticsDiv appuser={this.state.appuser} weekentered={this.state.weekentered} standings={this.state.standings}
                  addNewWeight={this.addNewWeight} week={this.state.week} maxweek={this.state.maxweek} mgn={this.state.admin} iconStyle={this.state.iconStyle} />}
              />}
              {/* <Route path="/analytics/"
                render={(props) => <AnalyticsDiv appuser={this.state.appuser} weekentered={this.state.weekentered} standings={this.state.standings}
                  addNewWeight={this.addNewWeight} week={this.state.week} maxweek={this.state.maxweek} mgn={this.state.admin} iconStyle={this.state.iconStyle} />}
              /> */}
              <Route path="/admin/"
                render={(props) => <AssumeRoleDiv mgn={this.state.admin} assumeUser={this.assumeUser} players={this.state.players} iconStyle={this.state.iconStyle} />}
              />
              <Route path="*" exact>
                <Redirect to="/" />
              </Route>

            </Switch>
          </Router>
        }
      </div>
    );
  }
}

const AssumeRoleDiv = (props) => {
  return (
    <div>
      <Menu iconStyle={props.iconStyle} mgn={props.mgn} />
      {props.mgn && <AssumeRole onSubmit={props.assumeUser} items={props.players} />}
    </div>
  )
}



const CompetitionsDiv = (props) => {
  return (
    <div>
      <Competitions competitions={props.competitions} setCurrentCompetition={props.setCurrentCompetition}
        addEmptyItem={props.addEmptyItem} saveNewCompetition={props.saveNewCompetition} mgn={props.mgn}
      />
    </div>
  )
};

export default App;
