import React from 'react'


const Rawdata = (props) => {
  return (
    <div className='rawdata'>
      <div style={{ fontSize: 30, fontWeight: "bold", color: "white", borderBottom: "solid" }}>Weekly Data</div>
      {props.rawdata
        .sort((a, b) => {
          return parseInt(b.week, 10) - parseInt(a.week, 10);
          // if(b.week < a.week) { return 1; }
          // if(b.week > a.week) { return -1; }
          // return 0;
        })
        .map(row => <RawdataRow key={row.week} {...row} />)}
    </div>
  );
};

const RawdataRow = (props) => {
  return (
    <div>
      <div className='rawdataGrid'>
        <div className='rawdataTitle'></div>
        <div className='standingsRow'>{props.week}</div>
        <div className='standingsRow'>{props.weight}</div>
      </div>
    </div>
  );
};
export default Rawdata;
