import React from 'react';
import Standings from './Standings';
import Form from './EnterWeightView';
import Menu from './Menu';

export default function OverallView(props) {
    return (
        <div>
            <Menu iconStyle={props.iconStyle} mgn={props.mgn} />
            {props.appuser && props.weekentered && <Standings title='Current Standings' standings={props.standings
                .map(
                    (row, index) => {
                        return { ...row, lossFloat: parseFloat(row.losstotal) }
                    })
                .sort((a, b) => {
                    if (b.lossFloat > a.lossFloat) { return 1; }
                    if (b.lossFloat < a.lossFloat) { return -1; }
                    return 0;
                }).map((row, index) => {
                    return {
                        ...row, rank: index === 0 ? 'Rank' : index, losslbs: index === 0 ? 'Lb Loss' : (parseFloat(row.startingweight) - parseFloat(row.currentweight)).toFixed(1)
                        , uptodate: (row.lastweek !== props.maxweek) && row.player !== 'Player', loss: row.losstotal
                    }
                })
            } />}
            {props.appuser && !props.weekentered && <Form onSubmit={props.addNewWeight} week={props.week} iconStyle={props.iconStyle} />}
        </div>
    );
};