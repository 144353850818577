module.exports = {

    apiKey: "AIzaSyBMlGUP1cJ5ez4zQgKaO3QNRYCuDRRXhxw",
    authDomain: "calorieninja.firebaseapp.com",
    databaseURL: "https://calorieninja.firebaseio.com",
    projectId: "calorieninja",
    storageBucket: "calorieninja.appspot.com",
    messagingSenderId: "1057742041589",
    appId: "1:1057742041589:web:bf6314d9691b61b1eaf0bc",
    measurementId: "G-DQQ4JXV18W"

}