import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import StorageIcon from '@material-ui/icons/Storage';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import { FormatListNumbered as StandingsIcon } from '@material-ui/icons';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Link } from 'react-router-dom';

const Menu = (props) => {
    return (
        <div className='leftAlign'><Link to={"/"} ><HomeIcon style={props.iconStyle} color="primary" /></Link>
            <Link to={"/standings/"} ><StandingsIcon style={props.iconStyle} /></Link>
            <Link to={"/oneweek/"} ><LooksOneIcon style={props.iconStyle} /></Link>
            <Link to={"/biweek/"} ><LooksTwoIcon style={props.iconStyle} /></Link>
            <Link to={"/rawdata/"} ><StorageIcon style={props.iconStyle} /></Link>
            <Link to={"/analytics/"} ><AssessmentIcon style={props.iconStyle} /></Link>
            {props.mgn && <Link to={"/admin/"} ><SupervisorAccountIcon style={props.iconStyle} /></Link>}
        </div>
    )
};

export default Menu;