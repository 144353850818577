import React from 'react';
import Standings from './Standings';
import Form from './EnterWeightView';
import Menu from './Menu';

export default function AnalyticsView(props) {
    return (
        <div>
            <Menu iconStyle={props.iconStyle} mgn={props.mgn} />
            {props.appuser && props.weekentered && <Standings title='Analytics' standings={props.standings
                .map(
                    (row, index) => {
                        return { ...row, lossFloat: parseFloat(row.losstotal) }
                    })
                .sort((a, b) => {
                    if (b.lossFloat > a.lossFloat) { return 1; }
                    if (b.lossFloat < a.lossFloat) { return -1; }
                    return 0;
                }).map((row, index, arr) => {
                    return {
                        ...row, rank: index === 0 ? 'Rank' : index, loss: index === 0 ? 'Lbs Behind' : (isNaN(parseFloat(arr[index - 1].losstotal)) ? 0 : ((parseFloat(row.startingweight) * (parseFloat(arr[index - 1].losstotal) / 100)) - (parseFloat(row.startingweight) - parseFloat(row.currentweight))).toFixed(1))
                        , losslbs: index === 0 ? 'Lbs Off Lead' : (isNaN(parseFloat(arr[1].losstotal)) ? 0 : ((parseFloat(row.startingweight) * (parseFloat(arr[1].losstotal) / 100)) - (parseFloat(row.startingweight) - parseFloat(row.currentweight))).toFixed(1))
                        , losstotal: index === 0 ? 'Lbs Off 2nd' : (isNaN(parseFloat(arr[2].losstotal)) ? 0 : ((parseFloat(row.startingweight) * (parseFloat(arr[2].losstotal) / 100)) - (parseFloat(row.startingweight) - parseFloat(row.currentweight))).toFixed(1))
                        , lastweek: index === 0 ? 'Lbs Off 3rd' : (isNaN(parseFloat(arr[3].losstotal)) ? 0 : ((parseFloat(row.startingweight) * (parseFloat(arr[3].losstotal) / 100)) - (parseFloat(row.startingweight) - parseFloat(row.currentweight))).toFixed(1))
                        , uptodate: (row.lastweek !== props.maxweek) && row.player !== 'Player'
                    }
                })
            } />}
            {props.appuser && !props.weekentered && <Form onSubmit={props.addNewWeight} week={props.week} iconStyle={props.iconStyle} />}
        </div>
    );
};