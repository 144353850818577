import React from 'react';
import Rawdata from './Rawdata';
import Menu from './Menu';

export default function RawdataDiv(props) {
    // var iconStyle = { textDecoration: 'none', color: 'grey', fontSize: '39px', margin: '9px' };
    return (
        <div>
            {/* <div className='leftAlign'><Link to={"/"} ><HomeIcon style={props.iconStyle} /></Link> <Link to={"/standings/"} ><StandingsIcon style={props.iconStyle} /></Link></div> */}
            <Menu iconStyle={props.iconStyle} mgn={props.mgn} />
            <Rawdata rawdata={props.rawdata} title={props.appuser} />
        </div>
    )
}